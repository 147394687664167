import React, { useEffect } from 'react';
import _ from 'lodash';
import Button from 'components/Button';
import CTA from 'components/CTA';
import styles from './detailedCardWithBackground.module.scss';

const DetailedCardWithBackground = ({ cards, position }) => {
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault(); // prevents the default right-click menu from appearing
    }
    // add the event listener to the component's root element
    const rootElement = document.getElementById('my-component');
    rootElement.addEventListener('contextmenu', handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  if (typeof window !== 'undefined') {
    document.addEventListener('contextmenu', e => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
  return (
    <div className={styles.maxWidth} id="my-component">
      <div className={styles.detailedCardWithBackground}>
        {cards?.length &&
          cards.map((card, index) => (
            <div
              key={index}
              className={
                position === 'center'
                  ? styles.centerAlign
                  : `text-${card.textAlign && card.textAlign.toLowerCase()}`
              }
            >
              <div>
                {card.icon && (
                  <div className={styles.cardIcon}>
                    {!card.icon.file && card.icon}
                    {card.icon.file && (
                      <img
                        style={{ 'pointer-events': 'none' }}
                        src={card.icon.file.url}
                        alt={card.icon.file.fileName}
                      />
                    )}
                  </div>
                )}
                {card.title && <h2>{card.title}</h2>}
                {card.description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: card.description?.childMarkdownRemark?.html,
                    }}
                    className={`text-${
                      card.textAlign && card.textAlign.toLowerCase()
                    }`}
                  ></div>
                )}
              </div>
              {card.buttons &&
                card.buttons.map((button, index) => (
                  <Button
                    key={`DetailedCardWithBackgroundButton${index}`}
                    type="app-default-white-button"
                    handleClick={() =>
                      _.isFunction(button.onClick) && button.onClick()
                    }
                  >
                    {button.title}
                  </Button>
                ))}

              {card.callToAction?.length && (
                <CTA
                  callToActions={card.callToAction}
                  extraStyle={
                    card.textAlign && card.textAlign === 'Center' && 'mx-auto'
                  }
                />
              )}

              {card.footer && (
                <Button
                  handleClick={() =>
                    _.isFunction(card.footer.onClick) && card.footer.onClick()
                  }
                  type={`app-white-link-button ${styles.appWhiteLinkButton}`}
                >
                  {card.footer.title}
                </Button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

DetailedCardWithBackground.defaultProps = {
  position: 'left',
};

export default DetailedCardWithBackground;
