import Button from 'components/Button';
import TextInput from 'components/form-elements/TextInput';
import TextArea from 'components/form-elements/TextArea';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import {
  AUCTION_INITIAL_VALUES,
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/firstToKnowForm';
import { Formik, Form } from 'formik';

import React from 'react';
import styles from './AuctionFirstToKnow.module.scss';
import { useState } from 'react';
import { format } from 'path';
const AuctionFirstToKnow = ({ submit, auction }) => {
  const [restOfForm, setRestOfForm] = useState(true);
  const cancel = React.useCallback(setTouched => {
    setTouched({}, false);
  }, []);

  AUCTION_INITIAL_VALUES.message = `I would like to know more about ${
    auction ? auction : ''
  } once further details are available.`;
  return (
    <div>
      <Formik
        initialValues={AUCTION_INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        validateOnMount
        onSubmit={(values, { resetForm }) => {
          submit(values).then(res => {
            resetForm();
            setRestOfForm(true);
          });
        }}
      >
        {({
          touched,
          isValid,
          isSubmitting,
          resetForm,
          setTouched,
          setFieldTouched,
        }) => (
          <Form className={styles.auctionForm} id="contact-us-modal">
            <div className={styles.headerGroup}>
              <h3>Learn More</h3>
              <div className={styles.fieldContainer}>
                <div className={styles.fieldGroup}>
                  <div className={styles.field}>
                    <TextInput
                      name="name"
                      placeholder="Name*"
                      showErrorMessages={false}
                      onClick={() => setRestOfForm(false)}
                    />
                  </div>

                  <div className={styles.field}>
                    <TextInput
                      name="email"
                      placeholder="Email*"
                      showErrorMessages={false}
                      onClick={() => setRestOfForm(false)}
                    />
                  </div>
                </div>

                <div
                  className={`${restOfForm && styles.displayRest} ${
                    styles.fieldGroup
                  }`}
                >
                  <div className={styles.field}>
                    <TextInput
                      className={styles.field}
                      name="confirmEmail"
                      placeholder="Confirm Email*"
                      showErrorMessages={false}
                    />
                  </div>
                  <div className={styles.field}>
                    <PhoneNumber
                      name="phoneNumber"
                      placeholder="Phone*"
                      showErrorMessages={false}
                      whiteBackGround={true}
                    />
                  </div>
                </div>
                <div
                  className={`${restOfForm && styles.displayRest} ${
                    styles.fieldGroup
                  }`}
                >
                  <div className={styles.textField}>
                    <TextArea
                      name="message"
                      handleClick={setFieldTouched}
                      showErrorMessages={false}
                    />
                  </div>
                </div>

                <div
                  className={`${restOfForm && styles.displayRest} ${
                    styles.footer
                  }`}
                >
                  <Button
                    type={`app-default-white-button ${styles.cancelButton}`}
                    handleClick={() => {
                      resetForm();
                      setRestOfForm(true);
                      setTouched({ message: false });
                      setFieldTouched('message', false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    btnType="submit"
                    type="app-primary-button"
                    disabled={
                      !!!Object.keys(touched).length || !isValid || isSubmitting
                    }
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AuctionFirstToKnow;
