import * as React from "react"

function BidderIcon(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <path
        fill="#1C5BAE"
        fillRule="evenodd"
        d="M29.836.915c1.118 0 2.027.9 2.027 2.013v15.365a2.02 2.02 0 01-2.027 2.013H18.555v10.456h-5.133V20.305H2.142a2.02 2.02 0 01-2.023-1.884l-.004-.128V2.928A2.02 2.02 0 012.142.915zm0 1.33H2.142a.69.69 0 00-.696.683v15.365c0 .375.31.682.696.682h12.611V29.43h2.47l.001-10.456h12.612a.69.69 0 00.696-.682V2.928a.69.69 0 00-.696-.682zM16.464 5.384c.248 0 .45.2.45.446v.989h.995c.248 0 .45.2.45.447a.449.449 0 01-.45.446h-1.927a.994.994 0 00-.995.989c0 .545.447.988.995.988h.964a1.89 1.89 0 011.894 1.882 1.89 1.89 0 01-1.894 1.882h-.032v.99c0 .246-.202.446-.45.446s-.45-.2-.45-.447v-.989h-.995c-.248 0-.45-.2-.45-.446 0-.247.202-.447.45-.447h1.927a.992.992 0 00.995-.989.992.992 0 00-.995-.988h-.964a1.89 1.89 0 01-1.894-1.882 1.89 1.89 0 011.894-1.882h.032V5.83c0-.246.202-.446.45-.446z"
      />
    </svg>
  )
}

export default BidderIcon
