import React from 'react';
import { saveAs } from 'file-saver';
import { writeAuctionToIcsFile } from './helper';

const Calendar = ({ auctionData }) => {
  const saveIcsFile = () => {
    saveAs(writeAuctionToIcsFile(auctionData), 'event.ics');
  };

  return (
    <div onClick={saveIcsFile} style={{ color: '#0053a5' }}>
      <a target="_blank" rel="noreferrer">
        Add To Calendar
      </a>
    </div>
  );
};

export default Calendar;
