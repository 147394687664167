import React from 'react';
import { navigate } from 'gatsby';
import VehicleCard from 'components/VehicleCard';
import Button from 'components/Button';
import { combineCloudinaryWithImages } from 'utils/image';
import {
  getAuctionYear,
  getPrice,
  getTag,
  getContentfulItemTitle,
} from 'shared/helpers/vehicles';
import {
  getAuctionStartAndEndDates,
  getCataloguePreFilteredByAuction,
} from 'shared/helpers/auctions';
import styles from './FeaturedVehicles.module.scss';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';

const FeaturedVehicles = ({ vehicles, auction }) => {
  const slugs = useWebPagesSlugs();
  const viewAllVehiclesFiltered = () => {
    navigate(
      getCataloguePreFilteredByAuction(
        auction,
        getAuctionYear(auction.subEvents),
        slugs[SLUGS.vehiclesCatalog]
      )
    );
  };

  return (
    <div className={`${styles.vehiclesListContainer} pt-5`}>
      <div className={styles.heading}>Featured Lots</div>
      <div className={styles.vehiclesList}>
        {vehicles.map((vehicle, index) => (
          <VehicleCard
            key={`vehicleCard_${index}`}
            fluidImages={combineCloudinaryWithImages(vehicle, 500, 3)}
            tag={getTag({
              ...vehicle,
              ...getAuctionStartAndEndDates(auction.subEvents),
            })}
            makeModel={getContentfulItemTitle(vehicle)}
            auction={`${getAuctionYear(auction.subEvents)} \xa0 | \xa0 ${
              auction.name
            }`}
            auctionSlug={auction}
            price={getPrice(vehicle, auction?.currency)}
            lotNumber={vehicle?.lotNumber}
            slug={vehicle?.slug}
            showDotsOnHover={true}
            salesForceId={vehicle?.salesForceId}
            onlineBiddingAvailable={vehicle.onlineBiddingAvailable}
          />
        ))}
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Button type="app-primary-button" handleClick={viewAllVehiclesFiltered}>
          View All Lots
        </Button>
      </div>
    </div>
  );
};

export default FeaturedVehicles;
