import React from 'react';

const SendIcon = () => {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8334 1.16663L13.1667 15.8333"
        stroke="#00C57D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.8334 1.16663L18.5001 27.8333L13.1667 15.8333L1.16675 10.5L27.8334 1.16663Z"
        stroke="#00C57D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default SendIcon;
