const moment = require('moment');
export const writeAuctionToIcsFile = auctionData => {
  const calendarEvent = `BEGIN:VCALENDAR\nVERSION:2.0\n${appendEvents(
    auctionData
  )}END:VCALENDAR`;
  const blob = new Blob([calendarEvent], {
    type: 'text/plain;charset=utf-8',
  });

  return blob;
};
export const appendEvents = auctionData => {
  const { description, subEvents, location } = auctionData;
  const mailingAddress = location.mailingAddress ?? null;
  const streetAddress = mailingAddress?.streetAddress ?? '';
  const addressLocality = ', ' + mailingAddress?.addressLocality ?? '';
  const addressCountry = ', ' + mailingAddress?.addressCountry ?? '';
  const locationFormat = streetAddress + addressLocality + addressCountry;
  let event = '';

  subEvents.map(subEvent => {
    event += `BEGIN:VEVENT\nDTSTART:${formatTime(
      subEvent['startDate']
    )}\nDTEND:${formatTime(subEvent['endDate'])}\nSUMMARY:${
      subEvent['name']
    }\nDESCRIPTION:${
      description['description']
    }\nLOCATION:${locationFormat}\nEND:VEVENT\n`;
  });

  return event;
};

const formatTime = date => {
  let formattedDate = moment(date).format('YYYYMMDDTHHmmssZ');
  return formattedDate.replace('+00:00', 'Z').split('+')[0];
};

export default {
  writeAuctionToIcsFile,
  appendEvents,
};
