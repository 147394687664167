import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import {
  AUCTION_INITIAL_VALUES,
  INITIAL_VALUES,
  TERMS_INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/firstToKnowForm';
import TextInput from 'components/form-elements/TextInput';
import TextArea from 'components/form-elements/TextArea';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import styles from './firstToKnow.module.scss';

import {
  INQUIRY_EMAIL_TITLE,
  INQUIRY_EMAIL_TYPES,
} from 'utils/inquiryEmailTypes';
import SendIcon from 'svg/SendIcon';
import BeFirstToKnowIcon from 'svg/BeFirstToKnowIcon';
import CloseIcon from 'svg/CloseIcon';
import { SLUGS } from 'utils/webPageTypesConstants';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import * as amplitude from '@amplitude/analytics-browser';
import { navigate } from 'gatsby';
import { registerToBidEvent } from 'utils/amplitude';
import { useAuth0 } from '@auth0/auth0-react';

const FirstToKnow = ({
  submit,
  modal,
  toggle,
  isForm,
  isDetails,
  isCatalogue,
  isRichText = false,
  isAuctionDetails,
  isPrivateSale,
}) => {
  const { user, isAuthenticated } = useAuth0();
  let header = '';
  if (!isRichText) {
    if ((isCatalogue && !isDetails) || isPrivateSale) {
      INITIAL_VALUES.message =
        'I am interested in this vehicle and would like to know more. ';
      header = 'Want to Learn More?';
    } else if (isDetails) {
      INITIAL_VALUES.message = '';
      header = 'Have Questions About This Past Sale?';
    } else {
      header = 'Be The First To Know';
      INITIAL_VALUES.message =
        'I would like to know when details of this vehicle have been published.';
    }
  }
  const slugs = useWebPagesSlugs();

  return (
    <>
      {isForm ? (
        <div
          className={`${isRichText ? styles.askContainer : styles.container}`}
        >
          <div className={styles.headerTitle}>
            <div
              className={`${styles.headerBody} ${
                isDetails ? styles.detailsText : ''
              }`}
            >
              <BeFirstToKnowIcon color={'#1C5BAE'} />
              <h3>{isRichText ? 'Send Us Your Question' : header}</h3>
            </div>
            {modal && (
              <CloseIcon
                styling={styles.closeIcon}
                onClick={toggle}
                color={'#696969'}
              />
            )}
          </div>

          <div className={styles.innerContainer}>
            <Formik
              initialValues={
                isRichText
                  ? TERMS_INITIAL_VALUES
                  : isAuctionDetails
                  ? AUCTION_INITIAL_VALUES
                  : INITIAL_VALUES
              }
              validationSchema={VALIDATION_SCHEMA}
              validateOnMount
              onSubmit={(values, { resetForm }) => {
                submit(values).then(res => {
                  resetForm();
                });
              }}
            >
              {({ touched, isValid, isSubmitting, setFieldTouched }) => (
                <Form id="first-to-know-form">
                  <div className={styles.field}>
                    <TextInput
                      name="name"
                      placeholder="Name *"
                      showErrorMessages={false}
                      className={styles.inputField}
                    />
                  </div>

                  <div className={styles.field}>
                    <TextInput
                      name="email"
                      placeholder="Email*"
                      showErrorMessages={false}
                      className={styles.inputField}
                    />
                  </div>

                  <div className={styles.field}>
                    <TextInput
                      className={styles.field}
                      name="confirmEmail"
                      placeholder="Confirm Email*"
                      showErrorMessages={false}
                    />
                  </div>
                  <div className={styles.field}>
                    <PhoneNumber
                      name="phoneNumber"
                      placeholder="Phone*"
                      showErrorMessages={false}
                      // label="&#42;"
                      label=" "
                    />
                  </div>

                  <div className={styles.textField}>
                    <TextArea
                      name="message"
                      showErrorMessages={false}
                      handleClick={setFieldTouched}
                      placeholder={isRichText && 'Your Message'}
                    />
                  </div>

                  <div className={styles.footer}>
                    <Button
                      btnType="submit"
                      type="app-primary-button"
                      disabled={
                        !!!Object.keys(touched).length ||
                        !isValid ||
                        isSubmitting
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.sentContainer}>
            <div className={styles.sentIcon}>
              <SendIcon />
            </div>
            <h3 className={styles.messageTitle}>Message Sent Successfully!</h3>
            {!isDetails && (
              <>
                {!isCatalogue && (
                  <p>
                    You will be notified when details of this vehicle are
                    published.
                  </p>
                )}
                <p className={styles.small}>
                  You can start your <span>registration to bid </span> and buy
                  the vehicle as soon as the auction becomes available.
                </p>
                <Button
                  handleClick={() => {
                    registerToBidEvent(isAuthenticated, user.email);
                  }}
                  type="app-primary-button AM-login-button"
                  path={`/${slugs[SLUGS.registerToBid]}`}
                  linkAsButton={true}
                >
                  Register to Bid
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default FirstToKnow;
