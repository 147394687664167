import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CopyClipboard from 'components/CopyClipboard';
import PhoneNumber from 'components/PhoneNumber'
import { nodePropType } from 'shared/propTypes';
import styles from './auctionInfo.module.scss';

const infoPropTypes = PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  time: PropTypes.string,
});

const AuctionInfo = ({ auctionInfo, padding, className }) => (
  <div className={styles.ways} style={{ padding }}>
    <div className={`${styles.waysText} ${className ? styles[className] : ''}`}>
      {auctionInfo &&
        auctionInfo.map((info, index) => (
          <div key={`auctionInfo${index}`}>
            {info.icon}
            <h3>{info.title}</h3>
            {info.info &&
              info.info.map((i, j) => (
                <div className={styles.viewingText} key={`info${j}`}>
                  {i.type === 'tel' ? (
                    <a href={`tel:${i.tel}`}>
                      {i.title}
                      <PhoneNumber number={i.tel}></PhoneNumber>
                    </a>
                  ) : i.type === 'email' ? (
                    <Fragment>
                      {i.title && (
                        <p className={styles.email}>
                          {i.title} <CopyClipboard id="email" text={i.email} />
                        </p>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {i.title && <div>{i.title}</div>}
                      {i.time && <p className={styles.bold}>{i.time}</p>}
                    </Fragment>
                  )}
                </div>
              ))}
            {info.subInfo && (
              <div className={styles.subInfoWrapper}>
                {info.subInfo.map((i, j) => (
                  <div className={styles.viewingText} key={`subInfo${j}`}>
                    {i.title && <p>{i.title}</p>}
                    {i.time && <p className={styles.bold}>{i.time}</p>}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  </div>
);

AuctionInfo.propTypes = {
  padding: PropTypes.string,
  auctionInfo: PropTypes.arrayOf(
    PropTypes.shape({
      icon: nodePropType,
      title: PropTypes.string,
      info: PropTypes.arrayOf(infoPropTypes),
      subInfo: PropTypes.arrayOf(infoPropTypes),
    })
  ),
};
AuctionInfo.defaultProps = {
  padding: '0',
};

export default AuctionInfo;
