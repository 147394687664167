import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styles from './TwitterFeed.module.scss';

const TwitterFeed = ({ twitterHandle }) => {
  return (
    <div className={styles.feed}>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName={twitterHandle}
        options={{height: 600}}
      />
    </div>
  );
};

export default TwitterFeed;
