import React from 'react';
import FirstToKnowForm from 'components/FirstToKnowForm';
import { Button, Modal } from 'reactstrap';
import styles from './firstToKnow.module.scss';
import CloseIcon from 'components/CloseIcon/CloseIcon';
import {
  INQUIRY_EMAIL_TITLE,
  INQUIRY_EMAIL_TYPES,
} from 'utils/inquiryEmailTypes';
const FirstToKnowModal = ({
  submit,
  isOpen,
  toggle,
  isDetails,
  url,
  sendEmail,
  salesForceId,
  lotNumber,
  vehicle,
  auction,
  isForm,
  toggleIsForm,
  isAuctionDetails,
  isCatalogue,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} contentClassName={styles.content}>
      <FirstToKnowForm
        sendEmail={sendEmail}
        url={url}
        salesforceId={salesForceId}
        submit={submit}
        toggle={toggle}
        modal={true}
        isDetails={isDetails}
        isForm={isForm}
        lotNumber={lotNumber}
        vehicle={vehicle}
        auction={auction}
        isAuctionDetails={isAuctionDetails}
        isCatalogue={isCatalogue}
      />
    </Modal>
  );
};
export default FirstToKnowModal;
