export const INQUIRY_EMAIL_TITLE = {
  BE_THE_FIRST_TO_KNOW: 'Be The First One To Know Request - ',
  LEARN_MORE_ABOUT_VEHICLE: 'Learn More About This Vehicle Request - ',
  HAVE_QUESTIONS_ABOUT_THIS_PAST_SALE: 'Have Questions About This Past Sale - ',
  ASK_QUESTION: 'Ask A Question',
  ASK_QUESTION_TERMS: 'Terms and Conditions Question Request',
};

export const INQUIRY_EMAIL_TYPES = {
  BE_THE_FIRST_TO_KNOW: 'BE_THE_FIRST_TO_KNOW',
  LEARN_MORE_ABOUT_VEHICLE: 'LEARN_MORE_ABOUT_VEHICLE',
  SOLD_VEHICLE: 'HAVE_QUESTIONS_ABOUT_THIS_PAST_SALE',
  ASK_QUESTION: 'ASK_QUESTION',
};
