import * as React from "react"

function MailIcon(props) {
  return (
    <svg width={28} height={22} viewBox="0 0 28 22" {...props}>
      <path
        fill="#1C5BAE"
        stroke="#1C5BAE"
        strokeWidth={0.5}
        d="M25.545 1H3c-1.09 0-2 .91-2 2v16c0 1.09.91 2 2 2h22.545c1.091 0 2-.91 2-2V3c0-1.09-.909-2-2-2zM3 2.09h22.545c.073 0 .11 0 .182.037l-7.963 7.928-.764.763-2.727 2.764-2.728-2.727-.763-.764-7.964-7.964c.073-.036.11-.036.182-.036zM2.09 3v-.073l7.928 7.928-7.927 7.89V3zm23.455 16.91H3a.735.735 0 01-.4-.11l8.182-8.182 3.127 3.127c.11.11.255.146.4.146h.036a.448.448 0 00.364-.182l3.091-3.09 8.182 8.181a.969.969 0 01-.437.11zm.91-1.165l-7.928-7.89 7.928-7.928v15.818z"
      />
    </svg>
  )
}

export default MailIcon