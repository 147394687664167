import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import AuctionDetails from 'features/auctionDetails';
import AuctionDetailsOnline from 'features/AuctionDetailsOnline';
import { isOnlineAuction } from 'src/utils/auctionTypes';
import { useAuth0 } from '@auth0/auth0-react';

const Auctions = props => {
  const { user } = useAuth0();
  return (
    <Fragment>
      {isOnlineAuction(
        props.data.contentfulWebPageAuction?.auction?.eventAttendanceMode
      ) ? (
        <AuctionDetailsOnline
          featuredVehicles={props.data.allContentfulLot.edges.map(edge => ({
            ...edge?.node.item,
            ...edge.node,
          }))}
          auction={{
            ...props.data.contentfulWebPageAuction.auction,
            ...props.data.contentfulWebPageAuction,
          }}
          user={user}
          slug={props.data.contentfulWebPageAuction.slug}
        />
      ) : (
        <AuctionDetails
          auction={props.data.contentfulWebPageAuction}
          user={user}
          slug={props.data.contentfulWebPageAuction.slug}
        />
      )}
    </Fragment>
  );
};

export default Auctions;

export const query = graphql`
  query auctions($id: String, $contentful_id: String) {
    contentfulWebPageAuction(contentful_id: { eq: $id }) {
      title
      slug
      subTitle {
        childMarkdownRemark {
          html
        }
      }
      featureImage {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      vehicleImage {
        fluid(maxWidth: 1800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      auction {
        contentful_id
        name
        currency
        metaTitle
        metaDescription {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        subEvents {
          ... on ContentfulSubEventAuction {
            contentful_id
            name
            startDate
            endDate
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSubEventViewing {
            name
            startDate
            endDate
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSubEventShuttles {
            id
            name
            startDate
            endDate
            contentful_id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
        description {
          childMarkdownRemark {
            html
          }
          description
        }
        eventAttendanceMode
        eventStatus
        activeAuction
        offers {
          ... on ContentfulOffer {
            contentful_id
            name
            eligibleQuantity
            eligibleCustomerType {
              title
            }
            price
            acceptedPaymentMethod
            category
            availability
            priceCurrency
            includesObject
          }
          ... on ContentfulBuyersPremium {
            contentful_id
            name
            sys {
              contentType {
                sys {
                  contentful_id
                }
              }
            }
            description {
              description
            }
          }
        }
        location {
          contentful_id
          name
          description
          venueLocation {
            lat
            lon
          }
          mailingAddress {
            ... on ContentfulAddress {
              id
              streetAddress
              postalCode
              postOfficeBoxNumber
              contentful_id
              addressRegion
              addressLocality
              addressCountry
            }
          }
          address {
            ... on ContentfulAddress {
              id
              streetAddress
              postalCode
              postOfficeBoxNumber
              contentful_id
              addressRegion
              addressLocality
              addressCountry
            }
            ... on ContentfulAddressFreeform {
              id
              addressCountry
            }
          }
        }
        conditionOfSale {
          contentful_id
          file {
            url
          }
        }
        organizer {
          name
          youTubeChannelId
          twitterHandle
          contactPoint {
            email
            faxNumber
            name
            telephone
          }
        }
        lot {
          item {
            ... on ContentfulVehicle {
              id
              salesForceId
            }
            ... on ContentfulAutomobilia {
              id
              salesForceId
            }
          }
        }

        webpage__auction {
          ctaList {
            buttonText
            style
            id
            action {
              ... on ContentfulWebRedirectAction {
                url
                openInANewTab
              }
              ... on ContentfulWebOpenFormAction {
                form
              }
              ... on ContentfulWebDownloadAction {
                file {
                  file {
                    url
                  }
                }
              }
              ... on ContentfulWebDynamicRedirectAction {
                url
                condition
                conditionFailureText
              }
            }
          }
          auctionStartDateText
          detailsList {
            headline
            style
            paragraph {
              childMarkdownRemark {
                html
              }
            }
            icon {
              description
              file {
                url
              }
            }
          }

          sideBySideSection {
            title
            leftParagraph {
              childMarkdownRemark {
                html
              }
            }
            rightParagraph {
              childMarkdownRemark {
                html
              }
            }

            ListCallToAction {
              buttonText
              style
              id
              action {
                ... on ContentfulWebRedirectAction {
                  url
                  openInANewTab
                }
                ... on ContentfulWebOpenFormAction {
                  form
                }
                ... on ContentfulWebDownloadAction {
                  file {
                    file {
                      url
                    }
                  }
                }
                ... on ContentfulWebDynamicRedirectAction {
                  url
                  condition
                  conditionFailureText
                }
              }
            }
          }
        }
      }
    }

    allContentfulLot(
      filter: {
        auction: { contentful_id: { eq: $contentful_id } }
        featured: { eq: true }
      }
      limit: 6
    ) {
      edges {
        node {
          slug
          lotNumber
          hasReservePrice
          askingPrice
          privateSalesPrice
          salePrice
          askingPrice
          lowEstimate
          highEstimate
          uniqueEstimate
          askingPriceOverride
          onlineBiddingAvailable
          item {
            __typename
            ... on ContentfulVehicle {
              id
              make {
                name
              }
              modelYear
              model
              salesForceId
              cloudinaryImagesCombined {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages1 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages2 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages4 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages5 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
              cloudinaryImages6 {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
            }

            ... on ContentfulAutomobilia {
              id
              title
              salesForceId
              description {
                childMarkdownRemark {
                  html
                }
              }
              cloudinaryImagesCombined {
                public_id
                height
                width
                context {
                  custom {
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
