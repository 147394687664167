import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import Button from 'components/Button';
import styles from './imageBanner.module.scss';

const ImageBanner = ({ title, description, buttons, fluidImage }) => (
  <div>
    <BackgroundImage className={styles.imageBanner} fluid={fluidImage}>
      <div className={styles.bannerText}>
        <h2>{title}</h2>
        <p>{description}</p>

        {buttons &&
          buttons.length &&
          buttons.map((button, index) => (
            <Button
              key={`imageBannerButton${index}`}
              type="app-transparent-white-button"
              linkAsButton={true}
              path={button.path}
            >
              {button.title}
            </Button>
          ))}
      </div>
    </BackgroundImage>
  </div>
);

ImageBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    })
  ),
};

export default ImageBanner;
