import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Button from 'components/Button';
import styles from './googleMap.module.scss';

const GoogleMap = ({ lat, lng, buttons }) => {
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: lat, lng: lng },
      map,
    });
    return marker;
  };
  return (
    <div className={styles.googleMap}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_G_MAPS_API_KEY }}
        defaultCenter={{ lat, lng }}
        defaultZoom={17}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>

      {buttons &&
        buttons.map((button, index) => (
          <Button
            key={`googleMapButton${index}`}
            handleClick={() => _.isFunction(button.onClick) && button.onClick()}
            type="app-transparent-blue-button"
          >
            {button.title}
          </Button>
        ))}
    </div>
  );
};

GoogleMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default GoogleMap;
