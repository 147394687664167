import React from 'react';
import STYLES from 'src/utils/stylesConstants';
import ReadMoreLess from '../ReadMoreLess';
import styles from './DetailsInfo.module.scss';

const DetailsInfo = ({ info, cutAfter, maxHeight }) => {
  const renderStepDesc = (content, style) => {
    switch (style) {
      case STYLES.readMoreLess:
        return (
          <div className={styles.viewingText}>
            <ReadMoreLess
              cutAfter={cutAfter}
              content={content || ''}
              isMarkdown={true}
              maxHeight={maxHeight}
              withShadow={false}
            />
          </div>
        );
      default:
        return (
          <div
            className={styles.viewingText}
            dangerouslySetInnerHTML={{
              __html: content || '',
            }}
          />
        );
    }
  };

  return (
    <div className={styles.detailsInfoWrapper}>
      <div className={styles.waysText}>
        <div>
          {info?.length &&
            info.map((row, index) => (
              <div
                className={`${styles.infoGrid} ${
                  row.headline.toLowerCase().includes('viewing')
                    ? styles.viewingSection
                    : ''
                }`}
                key={`vehicleSaleStep${index}`}
              >
                <div className={styles.leftSection}>
                  <div
                    style={{ backgroundImage: `url(${row?.icon?.file?.url})` }}
                    className={styles.img}
                  />
                  <h3>{row.headline}</h3>
                </div>
                <div className={styles.rightSection}>
                  {renderStepDesc(
                    row?.paragraph?.childMarkdownRemark?.html,
                    row?.style
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DetailsInfo;
